<template>
  <b-col cols="6">
    <div class="mb-1 align-items-center justify-content-between">
      <b-row>
        <b-col>
          <b-form-group :label="$t('BusquedaInstalacion')" label-for="filter-search">
            <b-row>
              <b-col sm="8">
                <b-form-input
                  v-model="search"
                  :placeholder="$t('BusquedaInstalacion')"
                  type="text"
                  class="d-inline-block"
                />
              </b-col>
              <b-col sm="4" style="padding-left: 0px">
                <b-button @click="handleSearch" variant="primary">
                  {{ $t("Buscar") }}
                </b-button>
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <b-table-simple hover small caption-top responsive>
      <b-thead>
        <b-tr>
          <b-th>
            <span v-if="type === 'assets'">{{ $t("Activos") }}</span>
          </b-th>
        </b-tr>
      </b-thead>
      <draggable
        :list="elements"
        :group="{ name: 'products', pull: 'clone', put: false }"
        tag="tbody"
      >
        <b-tr :key="index" v-for="(item, index) in elements">
          <b-td class="d-flex align-items-center justify-content-between py-2">
            <div>
              <div
                v-if="type === 'assets'"
                :style="`background-image: url('${item.image ? item.image : defaultImage }');`"
                class="d-inline-block img-product-list mr-50"
              ></div>
              <div
                v-if="type === 'products'"
                :style="`background-image: url('${item.imagen}');`"
                class="d-inline-block img-product-list mr-50"
              ></div>
              <p class="d-inline-block m-0 font-weight-bolder">
                <span class="d-block" v-if="item.tag && type !== 'products'"
                  >{{ item.name }} - {{ item.tag.code }}</span
                >
                <span class="d-block" v-else-if="type === 'products'">{{
                  item.CodAndName
                }}</span>
                <span class="d-block" v-else>{{ item.name }}</span>

                <b-badge
                  v-if="type === 'assets' && item.status"
                  :variant="statusAssets[item.status.alias]"
                  class="mr-50"
                >
                  {{ $t(`status.assets.${item.status.alias}`) }}
                </b-badge>
                <b-badge
                  v-if="type === 'sets'"
                  pill
                  variant="primary"
                  class="badge-sm"
                  >PACK</b-badge
                >
              </p>
            </div>
            <feather-icon
              @click="changeOpenPack(index)"
              icon="ChevronDownIcon"
              size="20"
              v-if="type === 'sets'"
            />
          </b-td>
          <draggable
            :list="item.objects"
            :group="{ name: 'products', pull: 'clone', put: false }"
            tag="tbody"
            v-if="type === 'sets' && openPack == index"
          >
            <b-tr :key="index1" v-for="(item1, index1) in item.objects">
              <b-td class="d-flex align-items-center px-3">
                <div
                  :style="`background-image: url('${item1.imagen}');`"
                  class="d-inline-block img-product-list mr-50"
                ></div>
                <div>
                  <p class="m-0">{{ item1.name }}</p>
                  <small v-if="item1.type === 'asset'">{{
                    $t("Activo")
                  }}</small>
                </div>
              </b-td>
            </b-tr>
          </draggable>
        </b-tr>
      </draggable>
    </b-table-simple>
    <div class="d-flex align-items-center justify-content-between mb-2">
      <div class="d-flex align-items-center mb-0">
        <span class="text-nowrap"> {{ $t("PorPagina") }}: </span>
        <b-form-select
          v-model="pageLength"
          :options="pages"
          class="ml-50 mr-1"
          @input="handlePageChange"
        />
        <span class="text-nowrap"> {{ $t("Total") }}: {{ totalElements }}</span>
      </div>
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalElements"
          :per-page="pageLength"
          first-number
          last-number
          align="right"
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
          @change="handleChangePage"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </div>
  </b-col>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import draggable from "vuedraggable";
import { config } from "@/shared/app.config";
import {
  BCol,
  BRow,
  BTh,
  BTr,
  BTd,
  BTableSimple,
  BThead,
  BPagination,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BBadge,
  BButton,
  BForm,
  BFormGroup,
  BInputGroupAppend,
} from "bootstrap-vue";

export default {
  name: "DraggableListAssets",
  components: {
    BCol,
    BRow,
    BTh,
    BTableSimple,
    BThead,
    BTr,
    BTd,
    draggable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BBadge,
    BButton,
    BForm,
    BFormGroup,
    BInputGroupAppend,
  },
  props: {
    event: {
      required: false,
    },
    productsSelected: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      type: "assets",
      openPack: 9999,
      searchTerm: "",
      search: "",
      group: "",
      currentPage: 1,
      pageLength: 10,
      pages: ["10", "15", "25"],
      statusAssets: config.statusAssetsVariants,
      defaultImage: require("@/assets/images/default/asset.png"),
    };
  },
  computed: {
    ...mapGetters({
      currentCompany: "companies/getCurrentCompany",
      currentClient: "clients/getCurrentClient",
      products: "products/getItems",
      assets: "assets/getItems",
      sets: "sets/getItems",
      totalProducts: "products/getTotalItems",
      totalAssets: "assets/getTotalItems",
      totalSets: "sets/getTotalItems",
      // selectGroups: "groups/getSelectGroups",
    }),
    elements() {
      let output = [];
      console.log(this.type);
      if (this.type === "products") {
        this.products.forEach((element) => {
          element.type = "product";
          output.push(element);
        });
      } else if (this.type === "assets") {
        output = this.assets
          .filter(
            ({ id }) => !this.productsSelected.some((asset) => asset.id === id)
          )
          .map((asset) => {
            asset.type = "asset";
            return asset;
          });

        // this.assets.forEach((element) => {
        //   element.type = "asset";
        //   output.push(element);
        // });
      } else if (this.type === "sets") {
        this.sets.forEach((element) => {
          element.type = "set";
          const arrayObjects = [];
          element.objects.forEach((obj) => {
            obj.object.type = obj.type;
            arrayObjects.push(obj.object);
          });
          element.objects = arrayObjects;
          output.push(element);
        });
      }
      return output;
    },
    totalElements() {
      if (this.type === "products") {
        return this.totalProducts;
      } else if (this.type === "assets") {
        return this.totalAssets;
      } else if (this.type === "sets") {
        return this.totalSets;
      }
      return 0;
    },
    textFilterCat() {
      if (this.group) {
        return this.group.description;
      }
      return this.$t("Todos");
    },
    textType() {
      if (this.type === "products") {
        return this.$t("Productos");
      } else if (this.type === "assets") {
        return this.$t("Activos");
      } else if (this.type === "sets") {
        return this.$t("Agrupaciones");
      }
      return "";
    },
  },
  watch: {
    elements() {
      this.openPack = 999;
    },
    group() {
      this.chargeElements();
    },
    type() {
      this.chargeElements();
    },
  },
  methods: {
    ...mapActions({
      getProducts: "products/getListProducts",
      getAssets: "assets/getListAssets",
      getSets: "sets/getListSets",
      // getSelectGroups: "groups/getSelectGroups",
    }),
    chargeElements() {
      let group = "";
      this.openPack = 999;
      if (this.group) {
        group = this.group.id;
      }
      if (this.type === "products") {
        this.getProducts({
          company: this.currentCompany.id,
          page: this.currentPage,
          per_page: this.pageLength,
          search: this.searchTerm,
          group,
        });
      } else if (this.type === "assets") {
        this.getAssets({
          client: this.currentClient.id,
          page: this.currentPage,
          per_page: this.pageLength,
          search: this.searchTerm,
          group,
        });
      } else if (this.type === "sets") {
        this.getSets({
          client: this.currentClient.id,
          page: this.currentPage,
          per_page: this.pageLength,
          search: this.searchTerm,
        });
      }
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.chargeElements();
    },
    handlePageChange() {
      this.currentPage = 1;
      this.chargeElements();
    },
    handleSearch() {
      this.searchTerm = this.search;
      this.currentPage = 1;
      this.chargeElements();
    },
    setGroup(groupSelect) {
      this.group = groupSelect;
    },
    setType(type) {
      this.type = type;
    },
    changeOpenPack(index) {
      if (this.openPack === index) {
        this.openPack = 999;
      } else {
        this.openPack = index;
      }
    },
  },
  async created() {
    await this.chargeElements();
    // await this.getSelectGroups({ family_id: "" });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
