<template>
  <b-card>
    <div class="d-flex justify-content-between align-items-center mb-2">
      <h5 class="m-0 font-weight-bolder">
        {{ $t("DetallesTarea") }}
      </h5>
    </div>
    <!-- form -->
    <validation-observer ref="createTask">
      <div v-if="step == 1">
        <b-row>
          <b-col v-if="event && event.responsable_roles_object.length" md="4">
            <validation-provider
              #default="{ errors }"
              :name="$t('Usuarios')"
              rules="required"
            >
              <b-form-group
                :label="$t('Usuarios')"
                label-for="task-responsable"
              >
                <!-- <v-select
                  v-model="responsable"
                  label="name"
                  :filterable="false"
                  :options="selectResponsablesTask"
                  :placeholder="$t('Responsable')"
                  :disabled="canSelectUsers"
                  @search="handleSelectResponsablesTaskSearch"
                /> -->
                <BaseSelect
                  v-model="users"
                  :searchable="true"
                  :clearable="true"
                  :multiple="true"
                  :httpBody="{
                    id_company: currentCompany.id,
                    id_client: currentClient.id,
                    roles: event ? event.responsable_roles_object : '',
                  }"      
                  :resource="'/users'"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col v-if="event && event.user_roles_object.length" md="4">
            <validation-provider
              #default="{ errors }"
              :name="$t('Usuarios')"
              rules="required"
            >
              <b-form-group :label="$t('Usuarios')" label-for="task-users">
                <v-select
                  v-model="users"
                  label="name"
                  :filterable="false"
                  :options="selectUsersTask"
                  :placeholder="$t('Usuarios')"
                  :disabled="canSelectUsers"
                  @search="handleSelectUsersTaskSearch"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col v-if="event && event.responsable_roles_object.length" md="4">
            <validation-provider
              #default="{ errors }"
              :name="$t('Actions')"
              rules="required"
            >
              <b-form-group :label="$t('Actions')" label-for="form-clients">
                <v-select
                  v-model="form"
                  label="name"
                  :filterable="false"
                  :searchable="false"
                  :options="selectForms"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col cols="12 text-right">
            <b-button variant="primary" class="mt-2 mr-1" @click="nextStep(2)">
              {{ $t("Continuar") }}
            </b-button>
          </b-col>
        </b-row>
      </div>
      <div v-if="step == 2">
        <b-row>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('Nombre')"
              rules="required"
            >
              <b-form-group :label="$t('Nombre')" label-for="task-name">
                <b-form-input
                  v-model="name"
                  name="name"
                  :placeholder="$t('Nombre')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('Prioridad')"
            >
              <b-form-group :label="$t('Prioridad')" label-for="task-priority">
                <v-select
                  v-model="priority"
                  label="label"
                  :filterable="false"
                  :searchable="false"
                  :options="selectPriorities"
                  :placeholder="$t('Prioridad')"
                >
                  <template slot="option" slot-scope="option">
                    {{ $t("priority." + option.label) }}
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    {{ $t("priority." + option.label) }}
                  </template>
                </v-select>
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('Estado')"
              rules="required"
            >
              <b-form-group :label="$t('Estado')" label-for="task-status">
                <v-select
                  v-model="status"
                  label="name"
                  :options="statusOptions"
                  :placeholder="$t('Estado')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6" class="d-none">
            <b-form-group
              v-if="event && event.alias === 'ot'"
              :label="$t('Proyecto')"
              label-for="task-project"
            >
              <v-select
                v-model="project"
                label="name"
                :options="selectProjects"
                :placeholder="$t('Proyecto')"
                @search="handleSelectProjectsSearch"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <!--   <validation-provider
              #default="{ errors }"
              :name="$t('FechaInicio')"
              rules="required"
            > -->
            <b-form-group :label="$t('FechaInicio')" label-for="task-fechaini">
              <flat-pickr
                v-model="date_ini"
                :config="{ enableTime: true }"
                name="date_ini"
                class="form-control"
                placeholder="dd/mm/YYYY"
              />
            </b-form-group>
            <!--   <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider> -->
          </b-col>
          <b-col sm="6">
            <b-form-group :label="$t('FechaFin')" label-for="task-fechafin">
              <flat-pickr
                v-model="date_fin"
                :config="{ enableTime: true }"
                name="date_fin"
                class="form-control"
                placeholder="dd/mm/YYYY"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              :label="$t('TaskRecurrence')"
              label-for="task-recurrence"
            >
              <b-form-checkbox
                id="recurrence"
                v-model="recurrence"
                name="check-button-recurrence"
                switch
                class="mt-1"
                inline
              />
            </b-form-group>
          </b-col>
          <b-col v-if="recurrence" sm="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('ReviewFrecuency')"
              :rules="revisionPeriodicaRequired"
            >
              <b-form-group
                :label="$t('ReviewFrecuency')"
                label-for="product-subgrupo"
              >
                <v-select
                  v-model="frequency_type"
                  :filterable="true"
                  :searchable="true"
                  :options="selectRevisiones"
                  :placeholder="$t('ReviewFrecuency')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <!--   <b-col sm="6">
            <b-form-group
              :label="$t('ActivarNotificaciones')"
              label-for="task-notificaciones"
            >
              <b-form-checkbox v-model="notification" value="true" plain>
                <strong>{{ $t("ActivarNotificaciones") }}</strong>
              </b-form-checkbox>
            </b-form-group>
          </b-col> -->
          <b-col sm="12">
            <b-form-group
              :label="$t('Descripcion')"
              label-for="task-description"
            >
              <quill-editor v-model="description" :options="editorOption" />
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group :label="$t('Documentos')" label-for="task-documents">
              <ImageDropzone ref="documents" :files="files" max-files="10" />
            </b-form-group>
          </b-col>
          <b-col cols="12 d-flex align-items-center justify-content-between">
            <b-button variant="light" class="mt-2 mr-1" @click="prevStep(1)">
              {{ $t("Volver") }}
            </b-button>
            <b-button
              v-if="date_ini"
              variant="primary"
              class="mt-2 mr-1"
              @click="
                {
                  nextStep(3);
                }
              "
            >
              {{ $t("Continuar") }}
            </b-button>
          </b-col>
        </b-row>
      </div>
      <div v-if="step == 3">
        <b-row>
          <b-col cols="12">
            <b-row>
              <draggable-list-assets
                :event="event.alias"
                :productsSelected="productsSet"
              />
              <b-col cols="6">
                <b-list-group class="cursor-move box-drag-products-parent">
                  <b-list-group-item
                    class="d-flex justify-content-between align-items-center"
                  >
                    <h5 class="font-weight-bolder mb-0">
                      {{ $t("FacilitiesTask") }}
                    </h5>
                  </b-list-group-item>
                  <draggable
                    :list="productsSet"
                    :group="{ name: 'products' }"
                    @add="handleChange"
                    @end="handleChange"
                    @remove="handleChange"
                    @move="handleChange"
                  >
                    <b-list-group-item
                      v-for="(p, index) in productsSet"
                      v-if="p.type == 'asset'"
                      :key="index"
                      class="d-flex justify-content-between align-items-center"
                    >
                      <span>{{ p.name }}</span>
                      <b-button
                        variant="outline-danger"
                        @click="removeProduct(index)"
                      >
                        <feather-icon icon="TrashIcon" size="16" />
                      </b-button>
                    </b-list-group-item>
                  </draggable>
                  <b-list-group-item
                    v-if="event.alias === 'ot'"
                    class="d-flex justify-content-between align-items-center"
                  >
                    <h5 class="font-weight-bolder mb-0">
                      {{ $t("Productos") }}
                    </h5>
                  </b-list-group-item>
                  <draggable
                    v-if="event.alias === 'ot'"
                    :list="productsSet"
                    :group="{ name: 'products' }"
                    @add="handleChange"
                    @end="handleChange"
                    @remove="handleChange"
                    @move="handleChange"
                  >
                    <b-list-group-item
                      v-for="(p, index) in productsSet"
                      v-if="p.type == 'product'"
                      :key="index"
                      class="d-flex justify-content-between align-items-center"
                    >
                      <span>{{ p.name }}</span>
                      <b-button
                        variant="outline-danger"
                        @click="removeProduct(index)"
                      >
                        <feather-icon icon="TrashIcon" size="16" />
                      </b-button>
                    </b-list-group-item>
                  </draggable>
                  <div class="box-drag-products">
                    <feather-icon icon="UploadIcon" size="16" />
                    <p class="m-0">
                      {{ $t("ArrastraProductos") }}
                    </p>
                  </div>
                </b-list-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col v-if="actions.length" cols="12" class="d-none">
            <p>{{ $t("ListadoAcciones") }}</p>
            <draggable
              :list="actions"
              :group="{ name: 'tags' }"
              class="mb-1 cursor-move"
              @add="handleChangeActions"
              @end="handleChangeActions"
              @remove="handleChangeActions"
              @move="handleChangeActions"
            >
              <b-list-group-item
                v-for="(act, index) in actions"
                :key="index"
                tag="li"
              >
                <div>
                  <div class="ml-25">
                    <form-task
                      v-if="act.alias == 'form' && act.config"
                      :info="act"
                    />
                    <div v-else>
                      <b-card-text class="mb-0 font-weight-bold">
                        {{ act.name }}
                      </b-card-text>
                    </div>
                  </div>
                </div>
              </b-list-group-item>
            </draggable>
          </b-col>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-between"
          >
            <b-button variant="light" class="mt-2 mr-1" @click="prevStep(2)">
              {{ $t("Volver") }}
            </b-button>
            <b-button
              type="submit"
              variant="primary"
              class="mt-2 mr-1"
              @click="handleSubmit"
            >
              {{ $t("Enviar") }}
            </b-button>
          </b-col>
        </b-row>
      </div>
    </validation-observer>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import draggable from "vuedraggable";
// import {
//   BButton,
//   BForm,
//   BFormGroup,
//   BFormInput,
//   BRow,
//   BCol,
//   BCard,
//   BCardText,
//   BListGroupItem,
//   BTh,
//   BTr,
//   BTd,
//   BTableSimple,
//   BThead,
//   BListGroup,
//   BPagination,
//   BFormSelect,
//   BDropdown,
//   BDropdownItem,
//   BInputGroup,
//   BInputGroupAppend,
//   BFormCheckbox,
// } from "bootstrap-vue";
import { quillEditor } from "vue-quill-editor";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { FlatPickrToTimestampWithTime } from "@/libs/helpers";
import DraggableListAssets from "@/views/installation/DraggableListAssets";
import flatPickr from "vue-flatpickr-component";
import { config } from "@/shared/app.config";
import ImageDropzone from "@/components/elements/ImageDropzone/ImageDropzone";
import FormTask from "@/views/events/actions/FormTask.vue";
import BaseSelect from '@/components/ui/select/BaseSelect.vue'

export default {
  components: {
    // BButton,
    // BForm,
    // BFormGroup,
    // BFormInput,
    // BListGroup,
    // BRow,
    // BCol,
    // BCard,
    // BTh,
    // BTableSimple,
    // BThead,
    // BTr,
    // BTd,
    // BCardText,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    draggable,
    BaseSelect,
    // BListGroupItem,
    vSelect,
    flatPickr,
    ImageDropzone,
    FormTask,
    // BPagination,
    // BFormSelect,
    // BDropdown,
    // BDropdownItem,
    DraggableListAssets,
    // BInputGroup,
    // BInputGroupAppend,
    // BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      form: "",
      searchTerm: "",
      name: "",
      description: "",
      priority: "",
      date_ini: "",
      date_fin: "",
      actions: [],
      selectPriorities: config.priorities,
      responsable: "",
      status: "",
      project: "",
      event: "",
      group: "",
      trashZone: [],
      files: [],
      productsSet: [],
      users: [],
      currentPage: 1,
      pageLength: 10,
      pages: ["10", "15", "25"],
      step: 1,
      dataFirstStep: "",
      recurrence: false,
      notification: false,
      selectUsersTaskSearchTimeout: null,
      // selectResponsablesTaskTimeout: null,
      selectProjectsTimeout: null,
      frequency_type: "",
      revisionPeriodicaRequired: "",
      selectRevisiones: [
        { value: "weekly", label: this.$t("review_frequency.weekly") },
        { value: "monthly", label: this.$t("review_frequency.monthly") },
        { value: "quarterly", label: this.$t("review_frequency.quarterly") },
        { value: "biannual", label: this.$t("review_frequency.biannual") },
        { value: "yearly", label: this.$t("review_frequency.yearly") },
      ],
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
          ],
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      localeDatePicker: "languages/getLocaleDatePicker",
      currentClient: "clients/getCurrentClient",
      currentCompany: "companies/getCurrentCompany",
      selectActions: "actions/getSelectActions",
      selectUsersTask: "users/getSelectUsersTask",
      // selectResponsablesTask: "users/getSelectResponsablesTask",
      selectEvents: "events/getItemsTask",
      selectStatus: "tasks/getSelectStatus",
      selectProjects: "projects/getSelectProjects",
      selectedEvent: "events/getEvent",
      products: "products/getItems",
      totalProducts: "products/getTotalItems",
      selectGroups: "groups/getSelectGroups",
      selectForms: "forms/getItems",
    }),
    canSelectUsers() {
      if (this.event) {
        return false;
      }
      return true;
    },
    textFilterCat() {
      if (this.group) {
        return this.group.description;
      }
      return this.$t("ResumenEPIs");
    },
    statusOptions() {
      return this.selectStatus.map((option) => ({
        ...option,
        name: this.$t(`status.tasks.${option.alias}`),
      }));
    },
  },
  watch: {
    group() {
      this.chargeProducts();
    },
    selectedEvent() {
      if (this.selectedEvent) {
        this.actions = this.selectedEvent.actions;
      }
    },
    recurrence() {
      if (this.recurrence) {
        this.revisionPeriodicaRequired = "required";
      } else {
        this.revisionPeriodicaRequired = "";
      }
    },
    event() {
      let rolesResponsable = [];
      let rolesUsers = [];
      let typeForms = "";
      if (this.event) {
        typeForms = this.event.alias;
        rolesResponsable = this.event.responsable_roles_object;
        rolesUsers = this.event.user_roles_object;
      }
      // if (rolesResponsable.length > 0) {
      //   this.getSelectResponsableTask({
      //     company: this.currentCompany.id,
      //     client: this.currentClient.id,
      //     roles: rolesResponsable,
      //   });
      // } else {
      //   this.responsable = "";
      // }
      if (rolesUsers.length > 0) {
        this.getSelectUsersTask({
          company: this.currentCompany.id,
          client: this.currentClient.id,
          roles: rolesUsers,
        });
      } else {
        this.users = "";
      }
      this.getSelectForms({
        page: 1,
        per_page: 99999,
        search: "",
        type: typeForms,
      });
    },
  },
  methods: {
    ...mapActions({
      create: "tasks/create",
      getSelectActions: "actions/getSelectActions",
      // getSelectResponsableTask: "users/selectResponsablesTask",
      getSelectUsersTask: "users/selectUsersTask",
      getSelectEvents: "events/getList",
      getSelectStatus: "tasks/getSelectStatus",
      getSelectProjects: "projects/getSelectProjects",
      getEvent: "events/getEvent",
      getSelectForms: "forms/getList",
      getProducts: "products/getListProducts",
      getSelectGroups: "groups/getSelectGroups",
    }),
    chargeProducts() {
      let group = "";
      if (this.group) {
        group = this.group.id;
      }
      this.getProducts({
        company: this.currentCompany.id,
        page: this.currentPage,
        per_page: this.pageLength,
        search: this.searchTerm,
        group,
      });
    },
    handleSubmit() {
      this.$refs.createTask.validate().then((success) => {
        if (success) {
          const formData = this.createFormData();
          if (formData) {
            this.create({ task: formData });
          }
        }
      });
    },
    onChangeEvent() {
      if (this.event?.id) {
        this.getEvent(this.event.id);
      }
    },
    createFormData() {
      const data = this.dataFirstStep;
      if (this.date_ini) {
        data.append("date_ini", FlatPickrToTimestampWithTime(this.date_ini));
      } else {
        return data;
      }

      if (this.date_fin) {
        data.append("date_fin", FlatPickrToTimestampWithTime(this.date_fin));
      }
      if (this.actions.length > 0) {
        this.actions.forEach((element) => {
          data.append("actions[]", JSON.stringify(element));
        });
      } else {
        data.append("actions", "");
      }
      if (this.productsSet.length > 0) {
        this.productsSet.forEach((element) => {
          if (element.type === "product") {
            data.append("products[]", JSON.stringify(element));
          }
          if (element.type === "asset") {
            data.append("assets[]", JSON.stringify(element));
          }
          if (element.type === "set") {
            data.append("sets[]", JSON.stringify(element));
          }
        });
      } else {
        data.append("products", "");
        data.append("assets", "");
        data.append("sets", "");
      }
      if (this.users) {
        this.users.forEach((user) => {
          data.append("users[]", user.id);
        });
      } else {
        data.append("users", "");
      }
      data.append("name", this.name);
      if (this.event && this.event.alias === "ot" && this.project) {
        data.append("project_id", this.project.id);
      }
      data.append("status_task_id", this.status.id);
      // data.append("responsible_user_id", this.responsable.id);
      data.append("event_id", this.event.id);
      data.append("priority", this.priority.value);
      data.append("description", this.description);
      data.append("client_id", this.currentClient.id);
      data.append("notification", this.notification);
      data.append("recurrence", this.recurrence ? 1 : 0);
      if (this.recurrence) {
        data.append("frequency_type", this.frequency_type.value);
      }
      data.append("form_id", this.form.id);

      return data;
    },
    handleChangeActions() {
      const actionsAux = [];
      this.actions.forEach((element, index) => {
        let config = {};
        if (element.config) {
          config = element.config;
        }
        actionsAux.push({
          order: index + 1,
          id: element.id,
          name: element.name,
          alias: element.alias,
          config,
        });
      });
      this.actions = actionsAux;
    },
    handleChange() {
      let productsSetAux = [];
      const productsSetIds = [];
      const productsSetRepes = [];
      this.productsSet.forEach((element, index) => {
        productsSetIds.push(element.id);
      });
      this.productsSet.forEach((element, index) => {
        productsSetAux[index] = {
          order: index + 1,
          id: element.id,
          type: element.type,
          name: element.name,
        };
      });

      productsSetAux = this.getUniqueListBy(productsSetAux, "id");
      this.productsSet = productsSetAux;
    },
    getUniqueListBy(arr, key) {
      return [...new Map(arr.map((item) => [item[key], item])).values()];
    },
    removeProduct(index) {
      this.productsSet.splice(index, 1);
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.chargeProducts();
    },
    handlePageChange() {
      this.currentPage = 1;
      this.chargeProducts();
    },
    setGroup(groupSelect) {
      this.group = groupSelect;
    },
    nextStep(num) {
      if (num === 2) {
        this.name = this.form.name;
        this.status = this.statusOptions.find(({ alias }) => alias === "to_do");
      }
      this.$refs.createTask.validate().then((success) => {
        if (success) {
          if (num === 3) {
            this.dataFirstStep = this.$refs.documents.getFormData("documents");
          }
          this.step = num;
        }
      });
    },
    prevStep(num) {
      this.step = num;
    },
    handleSelectUsersTaskSearch(search) {
      if (this.selectUsersTaskSearchTimeout) {
        clearTimeout(this.selectUsersTaskSearchTimeout);
      }

      this.selectUsersTaskSearchTimeout = setTimeout(() => {
        this.getSelectUsersTask({
          company: this.currentCompany?.id,
          client: this.currentClient?.id,
          roles: this.event?.user_roles_object,
          search,
        });
      }, 500);
    },
    // handleSelectResponsablesTaskSearch(search) {
    //   if (this.selectResponsablesTaskTimeout) {
    //     clearTimeout(this.selectResponsablesTaskTimeout);
    //   }

    //   this.selectResponsablesTaskTimeout = setTimeout(() => {
    //     this.getSelectResponsableTask({
    //       company: this.currentCompany?.id,
    //       client: this.currentClient?.id,
    //       roles: this.event?.responsable_roles_object,
    //       search,
    //     });
    //   }, 500);
    // },
    handleSelectProjectsSearch(search) {
      if (this.selectProjectsTimeout) {
        clearTimeout(this.selectProjectsTimeout);
      }

      this.selectProjectsTimeout = setTimeout(() => {
        this.getSelectProjects({ client: this.currentClient?.id, search });
      }, 500);
    },
  },
  async mounted() {
    this.$store.commit("app/loading", true, { root: true });

    await this.getSelectEvents();
    this.event = this.selectEvents[0];

    await this.getSelectActions();
    await this.getSelectStatus();
    await this.chargeProducts();
    this.$store.commit("app/loading", false, { root: false });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
