<template>
  <div>
    <v-select
      :value="value"
      :options="selectList"
      :filterable="false"
      :clearable="clearable"
      :label="label"
      :reduce="reduce"
      @open="onOpen"
      @close="onClose"
      @search="(query) => (search = query)"
      :multiple="multiple"
      @input="(val) => $emit('input', val)"
      :readonly="readonly"
      :disabled="readonly"
    >
      <template #list-footer>
        <li v-show="hasNextPage" ref="load" class="loader">
          {{ $t('LoadingMoreOptions') }}
        </li>
      </template>
    </v-select>
  </div>
</template>

<script>
import { config } from '@/shared/app.config'
import vSelect from 'vue-select'
import axios from 'axios'

export default {
  components: {
    vSelect,
  },
  props: {
    value: {
      type: [Object, String, Number, Array],
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    resource: {
      type: String,
      default: null,
    },
    httpBody: {
      type: Object,
      default: null,
    },
    label: {
      type: String,
      default: 'name',
    },
    reduce: {
      type: Function,
      default: item => item,
    },
    filterBy: {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      observer: null,

      currentPage: 1,
      lastPage: 0,
      pageLength: 10,
      search: "",

      selectList: [],
      selected: "",
    };
  },
  computed: {
    hasNextPage() {
      let output = true;
      if (this.lastPage === this.currentPage) {
        output = false;
      }
      if (this.selectList.length === 0) {
        output = false;
      }

      return output;
    },
  },
  watch: {
    async search() {
      this.currentPage = 1;
      this.selectList = [];
      await this.chargeData();
    },
  },
  methods: {
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick();
        this.observer.observe(this.$refs.load)
      }
    },
    onClose() {
      this.observer.disconnect()
    },
    async chargeData() {
     const response = await axios.post(`${config.webApiBase + this.resource}/list`, {
        page: this.currentPage,
        per_page: this.pageLength,
        search: this.search,
        ...this.httpBody
      })
  
      if (response.data.data) {
        const data = this.filterBy ? 
          response.data.data.filter((item) => item[this.filterBy.key] === this.filterBy.value) 
          : response.data.data

        this.selectList = this.currentPage > 1 ? 
          [...this.selectList, ...data] : data

        this.lastPage = response.data.last_page ? response.data.last_page : response.data.meta.last_page
      } else {
        const data = this.filterBy ? 
          response.data.filter((item) => item[this.filterBy.key] === this.filterBy.value) 
          : response.data

        this.selectList = data
        this.lastPage = 1
      }

    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.currentPage += 1;
        await this.chargeData();
        ul.scrollTop = scrollTop;
      }
    },
  },
  async mounted() {
    await this.chargeData();
    this.observer = new IntersectionObserver(this.infiniteScroll);
  },
};
</script>

<style lang="scss" scoped>
.loader {
  text-align: center;
  color: #bbbbbb;
}
.form-control:disabled {
  background-color: #f7f5f0 !important;
}
</style>
